<template>
  <div>
    <portal to="assembling-dr-place">
      <span>
        <span class="text-subtitle2">,
          {{ $t('Place') + ': ' }}
        </span>

        <span class="text-h6 text-bold">
          {{ data.id }} <q-icon name="edit" @click="handleDRPlaceClick" />
        </span>

        <span
          v-if="data.weight"
        >
          {{ '/' + data.weight }}
        </span>

        <span
          v-if="data.dimensions"
        >
          {{ '/' + data.dimensions }}
        </span>
      </span>
    </portal>

    <delivery-request-places-modal ref="drPlacesModal" :place="data"/>

  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'
import DeliveryRequestPlacesModal from '../../components/modals/DeliveryRequestPlacesModal.vue'

export default {
  name: 'DynamicDeliveryRequestPlace',
  props: {
    data: {
      type: Object,
      default () {
        return { id: '' }
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    DeliveryRequestPlacesModal
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ])
  },
  methods: {
    handleDRPlaceClick () {
      return this.$refs.drPlacesModal.show()
    }
  }
}
</script>

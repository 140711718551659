<template>
  <q-dialog
    v-model="isOpen"
    persistent
    class="modal-lg"
  >
    <q-card>
      <q-card-section class="row items-center">
        <h6 class="q-my-none">
          {{ $t('Please select product') }}
        </h6>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="handleClose"
        />
      </q-card-section>

      <q-card-section class="q-pa-none border">
        <dynamic-products-collection :data="items" @click="handleClick" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Components
import DynamicProductsCollection from '../dynamic-components/dynamic-products-collection.vue'

export default {
  name: 'OrderProductTasksModal',
  components: {
    DynamicProductsCollection
  },
  data () {
    return {
      isOpen: false,
      items: [],
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      }
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    resolve () {},
    reject () {},
    show (items) {
      this.items = items
      this.isOpen = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    handleClose () {
      this.isOpen = false
      this.resolve(null)
    },
    handleClick (result) {
      this.isOpen = false
      this.resolve(result.data)
    }
  }
}
</script>

<template>
  <q-dialog v-model="isOpen" :maximized="true">
    <q-card style="min-width: 75vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Places') }}
        </div>

        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="hide"
        />
      </q-card-section>

      <!--      <q-card-section>-->
      <!--        <barcode-input-->
      <!--            :settings="{catchAll: false}"-->
      <!--            :out-focused="false"-->
      <!--            :has-max-width="false"-->
      <!--            :disabled="isLoading"-->
      <!--            @barcode="handleInput"-->
      <!--        />-->
      <!--      </q-card-section>-->

      <q-card-section class="q-pt-none" v-if="orderProductTask">
        <div
            class="row justify-center items-stretch"
        >
          <div
              class="col justify-center items-center text-center"
          >
            <img
                style="height: 76px; width: 100%; object-fit: contain;"
                :src="orderProductTask._embedded.orderProduct.productOfferRaw.image || fallbackImage"
                :alt="orderProductTask._embedded.orderProduct.productOfferRaw.name"
                @error="onImageLoadFailure"
            >
          </div>

          <div class="col-3 q-pa-xs text-center">

            <label v-if="orderProductTask._embedded.orderProduct.productOfferRaw.type === 'bundle'"
                   :class="`q-py-xs q-px-sm q-mr-sm rounded bg-accent text-caption text-h6 text-bold text-white`">
              Bundle
            </label>

            <div>
              <new-picking-place
                  v-if="orderProductTask._embedded && orderProductTask._embedded.place"
                  tooltip="Location"
                  :data="orderProductTask._embedded.place"
              />
            </div>
            <q-tooltip self="center middle">
              Location
            </q-tooltip>

          </div>

          <div
              class="col-md-2 col-lg-2 q-py-xs row justify-center items-center text-center text-h6 text-bold"
              style="min-height: 100%;"
          >
            <div>
              {{ orderProductTask.scannedCount + '/' + orderProductTask.count }}
            </div>

            <q-tooltip self="center middle">
              Quantity
            </q-tooltip>
          </div>

          <div class="col text-center">
            <strong tooltip="Name">
              {{ orderProductTask._embedded.orderProduct.productOfferRaw.name }}
              <span>({{ $t('ID') + ': ' + orderProductTask._embedded.orderProduct.productOfferRaw.id }})</span>
            </strong>

            <q-tooltip self="center middle">
              Name
            </q-tooltip>
          </div>

          <div class="col-md-2 col-lg-2 text-center">
            <strong tooltip="Barcode">{{ getBarcode(orderProductTask).join(', ') }}</strong>
            <q-tooltip self="center middle">
              Barcode
            </q-tooltip>
          </div>
        </div>
      </q-card-section>

      <q-card-section class="q-pt-none">

        <d-r-boxing :places="places" :is-loading-finished="!isLoading"/>

      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import DRBoxing from '@/apps/app/components/delivery-requests/DRBoxing.vue'
import newPickingPlace from '@/apps/app/components/picking/new-picking-place.vue'

export default {
  name: 'PlacesModal',
  emits: ['submit'],
  components: {
    newPickingPlace,
    DRBoxing,
  },
  data () {
    return {
      isLoading: false,
      options: this.appOptions,
      deliveryRequest: null,
      places: [],
      orderProductTask: null,
      isOpen: false,
      fallbackImage: 'assets/img/fallback-image/package.png',
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ])
  },
  methods: {
    ...mapMutations([
      'setDeliveryRequest',
      'addErrorNotification',
    ]),
    show (deliveryRequest, orderProductTask) {
      this.isOpen = true
      this.isLoading = true

      this.deliveryRequest = deliveryRequest
      this.orderProductTask = orderProductTask

      this.setDeliveryRequest(deliveryRequest)

      const query = {
        filter: [
          { type: 'eq', field: 'deliveryRequest', value: this.deliveryRequest?.id },
          // {
          //   type: 'leftjoin',
          //   field: 'items',
          //   parentAlias: 'drp',
          //   alias: 'drpi'
          // },
          // {
          //   type: 'orx',
          //   conditions: [
          //     {
          //       type: 'eq',
          //       field: 'orderProduct',
          //       value: this.orderProductTask._embedded.orderProduct.id,
          //       alias: 'drpi'
          //     },
          //     { type: 'isnull', field: 'id', alias: 'drpi' }
          //   ],
          //   where: 'and'
          // }
        ],
        'order-by': [
          { type: 'field', field: 'priority', direction: 'asc' }
        ],
        'per_page': 250
      }

      this.$service.deliveryServicePlace.getAll(query).then(places => {
        this.places = places.items

        this.places.forEach(place => {
          let hasMatchingItem = false

          // Check each item in the place
          place.items.forEach(item => {
            // Check if the orderProduct id matches
            if (item.orderProduct.id !== this.orderProductTask._embedded.orderProduct.id) {
              item._hidden = true // Set item's hidden property to true
            } else {
              hasMatchingItem = true
            }
          })

          // If none of the items matched, hide the place as well
          if (!hasMatchingItem) {
            place._hidden = true
          }
        })

        this.isLoading = false
      })
    },
    hide () {
      this.places = []

      this.setDeliveryRequest(null)

      this.isOpen = false
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    getBarcode (product) {
      if (product.instructions) {
        let barcodes = (product.instructions.find(({ type }) => type === 'barcode-scan') || { data: { barcode: [] } }).data.barcode

        return Object.values(barcodes)
      }

      if (product._embedded.orderProduct.productOfferRaw && Array.isArray(product._embedded.orderProduct.productOfferRaw.barcodes)) {
        return product._embedded.orderProduct.productOfferRaw.barcodes
      }

      return []
    },
  }
}
</script>

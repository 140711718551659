<template>
  <div>
    <div class="row items-center q-py-sm q-px-md">
      <div class="col row items-center justify-end">
        <portal-target
            v-show="hasQueue"
            name="assembling-queues"
            class="q-ml-sm"
            @change="handleQueueChange"
        />

        <q-btn
            :color="fastMode ? 'positive' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="$t('Fast Mode') + ' ' + (fastMode ? $t('ON') : $t('OFF'))"
            no-caps
            @click="handleFastModeChange"
        />

        <q-btn
            color="warning"
            text-color="dark"
            size="sm"
            :label="$t('Reset')"
            no-caps
            @click="handleReset"
        />

        <portal-target
            v-show="hasQueue"
            name="assembling-buttons"
            class="q-ml-sm"
            @change="handleQueueChange"
        />
      </div>
    </div>

    <portal-target name="assembling-body"/>

    <div class="border rounded q-ma-sm">
      <div class="text-dark q-px-sm row items-stretch">
        <div
            class="col text-h6 border-right row items-center q-px-xs"
            style="min-height: 100%;"
        >
          <div>
            {{ $t('Packing workstation') + ': ' }}
          </div>

          <div v-if="place" class="q-ml-sm">
            {{ place.id }}
          </div>

          <div
              v-else
              class="col text-center text-white bg-amber rounded q-pa-sm q-ma-sm"
              style="line-height: 1.2;"
          >
            {{ $t('Please scan barcode of your assembly location to start work!') }}
          </div>
        </div>

        <div
            class="col q-px-xs row items-center"
            style="min-height: 72px;"
        >
          <div class="col">
            <barcode-input
                v-if="!isLoading && !isHiddenField"
                :out-focused="isFocused"
                :has-max-width="false"
                :disabled="isLoading"
                @barcode="handleBarcode"
            />
          </div>
        </div>
      </div>

      <div
          v-show="hasOrder"
          class="row items-center border-top q-pa-sm"
      >
        <portal-target
            name="assembling-dr"
            @change="handleDRChange"
        />

        <div v-if="!hasDR" class="text-h6 text-bold">
          {{ $t('No shipment') }}
        </div>

        <q-space/>

        <portal-target name="assembling-items"/>
      </div>

      <div
          v-show="hasOrder"
          class="row items-center border-top q-pa-sm"
      >
        <div class="col-4">
          <portal-target name="assembling-delivery-service"/>
        </div>

        <div class="col-4 q-pt-xs">
          <portal-target name="assembling-shop"/>
        </div>

        <div class="col-4">
          <portal-target
              name="assembling-order"
              @change="handleOrderChange"
          />
        </div>
      </div>
    </div>

    <div
        v-if="action === 'next'"
        class="q-pa-sm"
    >
      <order-assembling-content
          :fast-mode="fastMode"
          :barcode="barcode"
          @fast-mode="handleFastMode"
          @start-loading="handleStartLoading"
          @stop-loading="handleStopLoading"
          @block="handleBlock"
          @unblock="handleUnblock"
          @show-barcode-field="showBarcodeField"
      />
    </div>

    <printer-settings-modal
        ref="printerSettingsModal"
        @close="handlePrinterSettingsClose"
    />

    <div class="flex wrap justify-center" style="gap:20px" v-if="action === 'scanBasket'">
      <div class="pointer" v-for="place in storagePlaces" :key="place.id">
        <q-td
            style="width:280px;"
        >
          <new-picking-place
              :data="place"
              :type="place.type"
              @click="handleBarcode(createBarcodeData(place.code))"
              badge
          />
        </q-td>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import BarcodeInput from '../../components/barcode-input/BarcodeInput'
import OrderAssemblingContent from './OrderAssemblingContent'
import PrinterSettingsModal from '../../components/modals/PrinterSettingsModal.vue'
import NewPickingPlace from '../../components/picking/new-picking-place.vue'

export default {
  name: 'OrderAssembling',
  components: {
    BarcodeInput,
    OrderAssemblingContent,
    PrinterSettingsModal,
    NewPickingPlace
  },
  data () {
    return {
      hasDR: false,
      isFocused: false,
      action: 'scanBasket',
      queues: [],
      barcode: null,
      isLoading: false,
      isHiddenField: false,
      fastMode: false,
      hasOrder: false,
      hasQueue: false,
      storagePlaces: null,
      serverParamsToGetStoragePlaces: {
        '?per_page': '25',
        'page': '1',
        'order-by': [
          { 'type': 'field', 'field': 'created', 'direction': 'desc' }
        ],
        'filter': [
          { 'type': 'in', 'field': 'state', 'values': ['active', 'normal', 'blocked'] },
          { 'type': 'instanceof', 'field': 'p', 'class': 'Orderadmin\\Storage\\Entity\\Place\\Assembly' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'printer',
      'place',
      'assemblingQueues'
    ]),
  },
  mounted () {
    this.$service.printer.turnOnReconnect()

    if (!this.place) {
      this.handleLoadStoragePlaces()
    } else {
      this.action = 'scanBasket'

      this.handleBarcode(this.createBarcodeData(`S/P/${this.place.id}*`))
    }
  },
  unmounted () {
    this.$service.printer.turnOffReconnect()

    if (this.place) {
      this.$channels.user.publish('closePlace', this.place.id)
    }
  },
  methods: {
    ...mapActions([
      'loadStoragePlaces',
    ]),
    ...mapMutations([
      'setPrinter',
      'addErrorNotification',
      'setPlace',
      'setAssemblingQueues',
    ]),
    createBarcodeData (barcode) {
      const raw = `${barcode}`.trim()
      const expr = /[A-z/]+\/([0-9A-z/]+)/

      if (!raw.match(expr)) {
        return {
          raw,
          value: raw,
          type: ''
        }
      }

      const data = raw
          .split('*')[0]
          .split('/')
          .reduce((acc, value) => {
            if (isNaN(Number(value)) && !acc.value) {
              acc.type += `${value}/`
            } else {
              acc.value += `${value}/`
            }

            return acc
          }, { value: '', type: '', raw })

      data.value = data.value.slice(0, data.value.length - 1)
      data.type = data.type.slice(0, data.type.length - 1)

      return data
    },
    handleLoadStoragePlaces () {
      return this.loadStoragePlaces(this.serverParamsToGetStoragePlaces)
          .then(({ items, totalPages, page, totalItems }) => {
            this.storagePlaces = items
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            return Promise.resolve({ items, totalPages, page, totalItems })
          }).then(() => {
            if (this.$route.params.place) {
              this.handleBarcode(this.createBarcodeData(`S/P/${this.$route.params.place}*`))
            }
          })
    },
    handleDRChange (hasDR) {
      this.hasDR = hasDR
    },
    handleQueueChange (hasQueue) {
      this.hasQueue = hasQueue
    },
    handleOrderChange (hasOrder) {
      this.hasOrder = hasOrder
    },
    handleFastModeChange () {
      this.fastMode = !this.fastMode
    },
    handleFastMode (isOn) {
      this.fastMode = !!isOn
    },
    handlePrinterSettingsClose () {
      this.isFocused = false
    },
    handleReset () {
      this.action = 'scanBasket'
      this.queues = []
      this.barcode = null
      this.setPlace(null)
      this.showBarcodeField()
    },
    openPrinterSettings () {
      this.isFocused = true
      this.$refs.printerSettingsModal.open()
    },
    handleStartLoading () {
      this.isLoading = true
    },
    handleStopLoading () {
      this.isLoading = false
    },
    handleBarcode (barcode) {
      if (this.action === 'scanBasket') {
        if (barcode.type !== 'S/P') {
          this.addErrorNotification('Please, scan place. Barcode should start with S/P.')
          return
        }

        const isOpened = this.$utils.storeUtils.getLockedPlaceEvent(barcode.value)

        if (isOpened) {
          this.addErrorNotification(`Someone already work on this place!`)

          return Promise.resolve(null)
        }

        if (!this.place) {
          this.$service.storagePlace.get(barcode.value)
              .then(place => {
                this.setPlace(place)

                this.$channels.user.publish('openPlace', barcode.value, place)

                this.isHiddenField = true
                if (this.$route.params.place !== barcode.value) {
                  this.$router.push(`/workstation/packing/${barcode.value}`)
                }

                this.action = 'next'

                return Promise.resolve(place)
              }).then((place) => {
            const query = {
              filter: [
                {
                  type: 'eq',
                  field: 'workingPlace',
                  value: place.id
                },
                {
                  type: 'in',
                  field: 'adapter',
                  values: ['parallelAssembling', 'wholesale', 'discrete_assembling', 'ruled']
                }
              ]
            }

            return this.$service.assemblingQueue.getAll(query)
                .then(({ items }) => {
                  this.queues = items.map(data => {
                    return {
                      type: 'Orderadmin\\Storage\\Entity\\Assemblage\\Queue',
                      event: 'storage.assembling.queue.opened',
                      data
                    }
                  })

                  this.setAssemblingQueues(this.queues)

                  this.action = 'next'
                })
          })
        }

        if (this.assemblingQueues) {
          this.queues = this.assemblingQueues

          this.action = 'next'
        }
      }

      this.barcode = barcode
    },
    showBarcodeField () {
      this.isHiddenField = false
    },
    handleBlock () {
      this.isFocused = true
    },
    handleUnblock () {
      this.isFocused = false
    }
  }
}
</script>

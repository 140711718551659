<template>
  <q-dialog v-model="isOpen" :maximized="$q.screen.width < 700">
    <q-card style="min-width: 75vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Services') }}
        </div>


        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="hide"
        />
      </q-card-section>

      <!--      <q-card-section>-->
      <!--        <barcode-input-->
      <!--            :settings="{catchAll: false}"-->
      <!--            :out-focused="false"-->
      <!--            :has-max-width="false"-->
      <!--            :disabled="isLoading"-->
      <!--            @barcode="handleInput"-->
      <!--        />-->
      <!--      </q-card-section>-->


      <q-card-section class="q-pt-none">

        <div v-if="order"
             class="row justify-center no-border" style="border: none !important;">

          <div class="col-5 block text-center q-no-border">
            <supplies-table-labelling-order
                flat
                :ext-filter="extFilter"
                :order="order"
                :services="services"
                @on-dblclick="saveOrderSupplies"
                @change-catch-all="handleCatchAll"
                @changeServices="handleChangeServices"
            />
          </div>

          <q-separator vertical/>

          <div class="col-6 block text-center q-no-border">
            <added-supplies-table-order
                flat
                :order="order"
                :services="services"
                @change-catch-all="handleCatchAll"
                @changeServices="handleChangeServices"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import BarcodeInput from '../../components/barcode-input/BarcodeInput'
import SearchLive from '../../components/search/SearchLive'
import AddedSuppliesTableOrder from '@/apps/app/components/tables/AddedSuppliesTableOrder.vue'
import SuppliesTableLabellingOrder from '@/apps/app/components/tables/SuppliesTableLablellingOrder.vue'

export default {
  name: 'AddSuppliesModal',
  emits: ['submit'],
  components: {
    SuppliesTableLabellingOrder,
    AddedSuppliesTableOrder,
    BarcodeInput,
    SearchLive,
  },
  data () {
    return {
      options: this.appOptions,
      order: null,
      servicesOrder: [],
      extFilter: [],
      services: [],
      isLoading: false,
      pagination: {
        page: 1,
        rowsPerPage: 250
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left',
          headerClasses: 'bg-light-green-2'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left',
          headerClasses: 'bg-light-green-2'
        },
        {
          label: this.$t('Warehouse'),
          name: 'warehouse',
          align: 'left',
          headerClasses: 'bg-light-green-2'
        },
        {
          label: this.$t('Barcode'),
          name: 'barcode',
          align: 'left',
          headerClasses: 'bg-light-green-2'
        },
        {
          label: this.$t('Quantity'),
          name: 'quantity',
          align: 'left',
          headerClasses: 'bg-light-green-2'
        },
        {
          label: this.$t('Price'),
          name: 'price',
          align: 'left',
          headerClasses: 'bg-light-green-2'
        }

      ],
      rows: [],
      fallbackImage: 'assets/img/fallback-image/package.png',
      isOpen: false,
      acceptance: null,
      selectedItems: [],
      items: [],
      item: null,
      warehouse: null,
      model: {
        place: null
      },
      place: null,
      type: '',
      queue: {},
      dataObjects: {},
      barcode: '',
      prevSearch: ''
    }
  },
  watch: {
    isOpen () {
      this.services = []
    },
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ])
  },
  methods: {
    ...mapActions(['loadSupplies']),
    ...mapMutations([
      'addErrorNotification'
    ]),
    loadPath (place) {
      if (!place.path) {
        if (place.type === 'room') {
          return [(place.placeAlias || '')]
        }

        return [place.room, place.row, place.rack, place.shelf].map(x => x || 0)
      }

      return [...place.path, (place.placeAlias || '')]
    },
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    },
    getId (warehouse) {
      if (warehouse.id) {
        return warehouse.id
      }

      return warehouse._links.self.href.split('/').pop()
    },
    show (place, queue, dataObjects, order) {
      this.isOpen = true
      this.isLoading = true
      this.place = place
      this.queue = queue
      this.order = order

      this.dataObjects = dataObjects
      this.isLoading = false
    },
    hide () {
      this.isOpen = false
      this.services = []
    },
    openModal (supply) {
      this.$refs.quantityModal.open(supply)
    },
    submitQuantity (supply) {
      this.items.map(e => {
        if (e.id === supply.id) {
          e.quantity = supply.quantity
        }
      })
      const test = this.selectedItems.filter(e => {
        return e.id === supply.id
      })

      if (test.length > 0) {
        this.selectedItems.map(e => {
          if (e.id === supply.id) {
            e.quantity = supply.quantity
          }
        })
      } else {
        this.selectedItems.push(supply)
      }
    },
    handleInput (value) {
      const foundSupply = this.items.filter(e => e.barcode === value.raw)
      if (foundSupply.length > 0) {
        this.$refs.quantityModal.open(foundSupply[0])
      } else {
        this.addErrorNotification(`No supply with id ${value.raw} found`)
      }
    },
    save () {
      return this.saveItems()
    },
    saveOrderSupplies (service) {
      const finalService = { name: service.name, price: service.price, supply: service.id, count: service.quantity }
      return this.$service.productsService.save({ ...finalService, order: this.order })
          .then(item => {
            this.services = [item, ...this.services]
          })
    },
    handleCatchAll (value) {
      this.catchAll = value
    },
    handleChangeServices (services) {
      this.services = services
    },
    saveItems () {
      this.isLoading = true

      let entity = []
      this.selectedItems.forEach(e => entity.push({ id: e.id, quantity: e.quantity }))
      const data = {
        entity: entity,
        event: 'storage.box.opened',
        entityClass: 'Orderadmin\\Storage\\Entity\\Supply',
        timestamp: new Date().toISOString(),
        place: this.place
      }
      const task = this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task]

      if (task) {
        data.task = task.data.id
      }

      this.$service.assemblingQueue.process(this.queue, data, { filter: this.filter }, 'application/json+event')
          .catch(error => {
            this.addErrorNotification(error)
            this.isLoading = false
          })
          .finally(() => {
            this.hide()
          })
    },
  }
}
</script>
